<template>
  <div class="ai-symptom">
    <div class="symptom-title">
      <div class="symptom-left-line">
        {{
          mark == "sd"
            ? "水稻"
            : mark == "dd"
            ? "大豆"
            : mark == "xm"
            ? "小麦"
            : mark == "dp"
            ? "大棚"
            : ""
        }}病虫害图片
      </div>
    </div>
    <div class="symptom-list">
        <!-- @click="lookImg(item.img)" -->
      <div
        class="symptom-items"
        v-for="(item, index) in symptomList[mark]"
        :key="index"
      >
        <img :src="item.img" />
        <p>{{ item.title }}</p>
      </div>
    </div>
    <!-- <el-dialog :visible.sync="lookDialogVisible">
      <img width="100%" :src="dialogImageUrl" />
    </el-dialog> -->
  </div>
</template>

<script>
export default {
  name: "aiSymptom",
  props: {
    mark: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // lookDialogVisible: false,
      // dialogImageUrl: "",

      symptomList: {
        sd: [
          {
            img: require("@/assets/image/ai/sd/img1.png"),
            title: "稻瘟病",
          },
          {
            img: require("@/assets/image/ai/sd/img2.png"),
            title: "白叶枯",
          },
          {
            img: require("@/assets/image/ai/sd/img3.png"),
            title: "稻飞虱",
          },
          {
            img: require("@/assets/image/ai/sd/img4.png"),
            title: "水稻螟虫",
          },
          {
            img: require("@/assets/image/ai/sd/img5.png"),
            title: "稻纵卷叶螟",
          },
          {
            img: require("@/assets/image/ai/sd/img6.png"),
            title: "水稻纹枯病",
          },
          {
            img: require("@/assets/image/ai/sd/img7.png"),
            title: "稻曲病",
          },
          {
            img: require("@/assets/image/ai/sd/img8.png"),
            title: "水稻细菌性条斑病",
          },
        ],
        dd: [
          {
            img: require("@/assets/image/ai/dd/img1.png"),
            title: "豆天蛾虫",
          },
          {
            img: require("@/assets/image/ai/dd/img2.png"),
            title: "造桥虫",
          },
          {
            img: require("@/assets/image/ai/dd/img3.png"),
            title: "象鼻虫",
          },
          {
            img: require("@/assets/image/ai/dd/img4.png"),
            title: "食心虫",
          },
          {
            img: require("@/assets/image/ai/dd/img5.png"),
            title: "大豆蚜虫",
          },
          {
            img: require("@/assets/image/ai/dd/img6.png"),
            title: "根腐病",
          },
          {
            img: require("@/assets/image/ai/dd/img7.png"),
            title: "大豆灰斑病",
          },
          {
            img: require("@/assets/image/ai/dd/img8.png"),
            title: "大豆炭疽病",
          },
          {
            img: require("@/assets/image/ai/dd/img9.png"),
            title: "大豆紫斑病",
          },
          {
            img: require("@/assets/image/ai/dd/img10.png"),
            title: "大豆病毒病",
          },
        ],
        dp: [
          {
            img: require("@/assets/image/ai/dp/img1.png"),
            title: "白粉虱",
          },
          {
            img: require("@/assets/image/ai/dp/img2.png"),
            title: "蚜虫",
          },
          {
            img: require("@/assets/image/ai/dp/img3.png"),
            title: "红蜘蛛",
          },
          {
            img: require("@/assets/image/ai/dp/img4.png"),
            title: "茶黄螨",
          },
          {
            img: require("@/assets/image/ai/dp/img5.png"),
            title: "蓟马",
          },
          {
            img: require("@/assets/image/ai/dp/img6.png"),
            title: "潜叶蝇",
          },
          {
            img: require("@/assets/image/ai/dp/img7.png"),
            title: "蝗虫",
          },
          {
            img: require("@/assets/image/ai/dp/img8.png"),
            title: "立枯病",
          },
          {
            img: require("@/assets/image/ai/dp/img9.png"),
            title: "霜霉病",
          },
          {
            img: require("@/assets/image/ai/dp/img10.png"),
            title: "灰霉病",
          },
        ],
        xm: [
          {
            img: require("@/assets/image/ai/xm/img1.png"),
            title: "赤霉病",
          },
          {
            img: require("@/assets/image/ai/xm/img2.png"),
            title: "白粉病",
          },
          {
            img: require("@/assets/image/ai/xm/img3.png"),
            title: "小麦锈病",
          },
          {
            img: require("@/assets/image/ai/xm/img4.png"),
            title: "小麦叶枯病",
          },
          {
            img: require("@/assets/image/ai/xm/img5.png"),
            title: "小麦纹枯病",
          },
          {
            img: require("@/assets/image/ai/xm/img6.png"),
            title: "小麦黑穗病",
          },
          {
            img: require("@/assets/image/ai/xm/img7.png"),
            title: "小麦蚜虫",
          },
          {
            img: require("@/assets/image/ai/xm/img8.png"),
            title: "小麦吸浆虫",
          },
          {
            img: require("@/assets/image/ai/xm/img9.png"),
            title: "小麦根腐病",
          },
          {
            img: require("@/assets/image/ai/xm/img10.png"),
            title: "红蜘蛛",
          },
        ],
      },
    };
  },
  methods: {
    // lookImg(src) {
    //   this.dialogImageUrl = src;
    //   this.lookDialogVisible = true;
    // },
  },
};
</script>

<style lang="less" scoped>
.ai-symptom {
  width: 426px;
  height: 100%;
  background: #0e2138;
  padding: 52px 40px;
  margin: 0 0 0 8px;
  .symptom-title {
    width: 100%;
    display: flex;
    margin-bottom: 25px;
    padding-right: 30px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    .symptom-left-line {
      font-size: 18px;
      font-weight: 400;
      color: #fff;
      padding: 0 0 0 15px;
      position: relative;

      &::before {
        content: "";
        background-color: #3e90e5;
        width: 4px;
        height: 18px;
        border-radius: 2px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
      }
    }
  }
  .symptom-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .symptom-items {
      width: 167px;
      margin: 0 12px 18px 0;
      display: flex;
      align-items: center;
      flex-direction: column;
      cursor: pointer;
      &:nth-of-type(2n) {
        margin-right: 0;
      }
      img {
        width: 100%;
        height: 114px;
        background-color: #ccc;
      }
      p {
        font-size: 14px;
        color: #fff;
      }
    }
  }
}
</style>
